import { render, staticRenderFns } from "./QRPackagePage.vue?vue&type=template&id=fbb039ea&scoped=true&"
import script from "./QRPackagePage.vue?vue&type=script&lang=js&"
export * from "./QRPackagePage.vue?vue&type=script&lang=js&"
import style0 from "./QRPackagePage.vue?vue&type=style&index=0&id=fbb039ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbb039ea",
  null
  
)

export default component.exports