<template>
    <div>
      <v-app style="min-height: 100vh !important">
        <div>
          <v-row justify="center">
            <v-dialog v-model="auth_error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break: normal">
                  {{ $t("Warning_Message.Unauthorized") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="closeDialogUnauthorized"
                    style="background-color: #27AE60;"
                  >
                    {{ $t("Warning_Message.Ok") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
  
        <div>
          <v-row justify="center">
            <v-dialog v-model="error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break: normal">
                  {{ $t("Warning_Message.TimedOut_Error") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="closeDialogError" style="background-color: #1467BF;">
                    {{ $t("Warning_Message.Ok") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        <div style="margin-top: 5%">
          <h2 v-if="paymentGateway === 'KBZQR'"> {{ $t("Customer.QRCode.Payment_With_KBZPay") }}</h2>
          <h2 v-else-if="paymentGateway === 'CBPAYQR'"> {{ $t("Customer.QRCode.Payment_With_CBPay") }}</h2>
          <h2 v-else>{{ $t("Customer.QRCode.Payment_With_AYAPay") }}</h2>
  
          <p style="margin-top: 10px">{{ $t("Customer.QRCode.Please_Complete_The_Payment_Within_5_Minutes") }}</p>
          <h3>{{ $t("Customer.QRCode.Amount_To_Be_Paid") }} : {{ amount }} MMK</h3>
        </div>
        <div class="action" v-if="qr_code">
          <div>
            <VueQRCodeComponent
              :value="qr_code"
              :size="size"
              level="H"
            ></VueQRCodeComponent>
          </div>
          <div>
            <p style="color: black; font-weight:800" v-if="paymentGateway === 'KBZQR'"> {{ $t("Customer.QRCode.How_To_Make_Payment_With_KBZPay") }} </p>
            <p style="color: black; font-weight:800" v-else-if="paymentGateway === 'CBPAYQR'"> {{ $t("Customer.QRCode.How_To_Make_Payment_With_CBPay") }} </p>
            <p style="color: black; font-weight:800" v-else> {{ $t("Customer.QRCode.How_To_Make_Payment_With_AYAPay") }} </p>
  
            <v-list dense>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon dense color="black" size="12px">
                    mdi-checkbox-blank-circle</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-align: left;font-size:16px" v-if="paymentGateway === 'KBZQR'"> {{ $t("Customer.QRCode.Open_KBZPay_App") }} </v-list-item-title>
                  <v-list-item-title style="text-align: left;font-size:16px" v-else-if="paymentGateway === 'CBPAYQR'"> {{ $t("Customer.QRCode.Open_CBPay_App") }} </v-list-item-title>
                  <v-list-item-title style="text-align: left;font-size:16px" v-else> {{ $t("Customer.QRCode.Open_AYAPay_App") }} </v-list-item-title>
  
                </v-list-item-content>
              </v-list-item>
  
               <v-list-item>
                <v-list-item-icon>
                  <v-icon dense color="black" size="12px">
                    mdi-checkbox-blank-circle</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-align: left;font-size:16px">{{ $t("Customer.QRCode.Click_Scan_And_Pay") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
  
  
              <v-list-item>
                <v-list-item-icon>
                  <v-icon dense color="black" size="12px">
                    mdi-checkbox-blank-circle</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-align: left;font-size:16px">{{ $t("Customer.QRCode.Scan_QR_And_Complete_Payment") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
  
        </div>
        <div style="margin-top:2%">
         <div v-if="paymentStatus">
              <p>{{ $t("Customer.QRCode.Waiting_To_Complete_Pay") }}</p>
                 <v-progress-circular
                       indeterminate
                        color="primary"
                 ></v-progress-circular>
          </div>
           <div v-else>
                <img style="height:48px; width: 48px;" src="https://s3iconimages.mymedicine.com.mm/success_tick.svg"/>
              <p>Redirecting to success page</p>
          </div>
          </div>
      </v-app>
    </div>
  </template>
  
  <script>

import axios from "axios";
  import VueQRCodeComponent from "qrcode.vue";
import { axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';
  export default {
    name: "QRCode",
    data() {
      return {
        token: null,
        intervalValue: null,
        booking_data: {},
        booking_id: "",
        qr_code: "",
        size: 300,
        currentCustomer: "",
        auth_error_enable: false,
        error_enable: false,
        query_order_flag: false,
        timeout_count: 0,
        isMobile: false,
        paymentStatus:true,
        amount: "",
        paymentGateway: "",
      };
    },
    components: {
      VueQRCodeComponent,
    },
    methods: {
      userPageViewTable() {
          if (this.token === null) {
              var userPageViewTableBody = {
                  pageName : 'HBAPackageQRCode',
                  typeOfUser: 'CUSTOMER'
              }
          } else {
              var userPageViewTableBody = {
                  pageName: 'HBAPackageQRCode',
                  typeOfUser: 'CUSTOMER',
                  token: this.token
              }
          }
          axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
          .catch((userPageViewTableError) => {
              console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
          });
      },
      closeDialogUnauthorized() {
        this.auth_error_enable = false;
        this.$router.push({
          name: "Login",
        });
      },
      closeDialogError() {
        this.error_enable = false;
        this.$router.push({
          name: "CustomerHomeScreen",
        });
      },
      async verifyOrder() {
            let appointmentRes =  await axios_auth_instance_hba_customer.get(`/user/verifyPackagePayment/${this.booking_id}`);
            let paymentStatus = appointmentRes.data.bookingInfo.paymentStatus
            if (paymentStatus == 'PAID') {
                clearInterval(this.intervalValue);
                this.$router.push({
                    name: 'hospitalPackageBookingSuccess',
                    params: {
                        bookingInfo: { ...appointmentRes.data.bookingInfo, bookingID: this.booking_id},
                    }
                });
            } else if(paymentStatus == 'WAITING') {
                return;
            } else {
                console.log('Something else');
            }
        },
      query_order() {
        this.intervalValue = setInterval(this.verifyOrder, 10000);
      }
    },
    mounted() {
      if (!window.xm)
			  this.token = this.$cookies.get('customerToken');
		  else
			  this.token = localStorage.getItem('customerToken');

      this.isMobile = this.$device.mobile;
      if(this.$store.state.locale == 'en')
        document.title = "QR Code Payment"
      else
        document.title = 'QR ဖြင့်ငွေပေးချေမှု'
      this.currentCustomer = this.$cookies.get("customerToken");
      var access = this.$cookies.get("access");
      if (!this.currentCustomer) {
        this.$router.push({
          name: "Login",
        });
      } else {
        this.booking_id = this.$route.params.booking_id;
        this.qr_code = this.$route.params.qr_code;
        this.amount = this.$route.params.amount;
        this.paymentGateway = this.$route.params.paymentGateway
        this.booking_data = {
          token: this.currentCustomer,
          typeOfUser: "CUSTOMER",
          booking_id: this.booking_id,
          // trade_status: "",
        };
        this.query_order();
      }
    
      this.userPageViewTable();
    },
  };
  </script>
  
  <style scoped>
  .action {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  </style>